import WithdrawTable from "@/components/Withdraw/WithdrawTable/index";
import BaseIcon from "@/components/UI/BaseIcon/index";
import { mapGetters, mapActions } from "vuex";
import { handleInput, removeThousand } from "@/utiles/numericValidations";

export default {
  components: {
    WithdrawTable,
    BaseIcon,
  },
  data() {
    const validateAddress = (rule, value, callback) => {
      const reg = new RegExp(this.networkRegex);
      if (!value) {
        callback(new Error(this.$t("withdraw.enterAddress")));
      } else if (!reg.test(value)) {
        callback(new Error(this.$t("withdraw.invalidAddress")));
      } else {
        callback();
      }
    };
    const validateAmount = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t("withdraw.enterAmount")));
      } else if (this.pureAmount > this.maxAmount && this.transferType !== 18) {
        callback(
          new Error(
            this.$t("withdraw.maxWithdrawValidation", {
              maxAmount: this.maxAmount,
              coin: this.formInline.coin,
            })
          )
        );
      } else if (this.transferType !== 18 && (value === "0" || this.pureAmount < this.minAmount)) {
        callback(
          new Error(
            this.$t("withdraw.minWithdrawValidation", {
              minAmount: this.minAmount,
              coin: this.formInline.coin,
            })
          )
        );
      } else if (this.pureAmount > this.balance) {
        callback(new Error(this.$t("withdraw.insufficientBalance")));
      } else {
        callback();
      }
    };
    const validateSecurityPass = (rule, value, callback) => {
      if (this.securityForm.fundpwd === "") {
        callback(new Error(this.$t("withdraw.enterWithdrawPass")));
      } else {
        callback();
      }
    };
    return {
      baseUrl: process.env.VUE_APP_EXCOINO_PANEL_URL,
      isAddressValid: false,
      withdrawAmountRule: "",
      recordType: [],
      transferType: 18,
      countdown: 120,
      // formInline: {
      //     code: "",
      //     fundpwd: ""
      // },
      withdrawLoading: false,
      lastWithdrawLoading: false,
      setWithdrawPasswordLoading: false,
      showWithdrawAddress: false,
      addressDropDown: [],
      amountError: "",
      withdrawPasswordModal: false,
      userAccountSettings: {},
      codeIsSending: false,
      sendCodeValue: this.$t("withdraw.getCode"),
      networkRegex: "",
      formInline: {
        coin: "",
        network: "",
        address: "",
        memo: "",
        amount: "",
      },
      formInlineRules: {
        coin: {
          required: true,
          trigger: "change",
          message: this.$t("withdraw.selectCoin"),
        },
        network: {
          required: true,
          trigger: "change",
          message: this.$t("withdraw.selectNetwork"),
        },
        address: {
          required: true,
          trigger: "blur",
          validator: validateAddress,
        },
        memo: {
          required: true,
          trigger: "change",
          message: this.$t("withdraw.enterTag"),
        },
        amount: {
          required: true,
          trigger: "change",
          validator: validateAmount,
        },
      },
      securityForm: {
        code: "",
        fundpwd: "",
      },
      securityFormRules: {
        fundpwd: [
          {
            required: true,
            type: "string",
            message: this.$t("withdraw.enterWithdrawPass"),
            trigger: "blur",
          },
          {
            validator: validateSecurityPass,
            trigger: "change",
            message: this.$t("withdraw.enterWithdrawPass"),
          },
        ],
        code: [
          {
            required: true,
            message: this.$t("withdraw.enterCode"),
            trigger: "change",
          },
        ],
      },
      modal: false,
      // fundpwd: "",
      currentCoin: {
        balance: 0,
      },
      tablePaginate: {
        page: 0,
        pageSize: 10,
        total: 0,
      },
      tableLoading: true,
      withdrawAdress: "",
      inputAddress: "", //the address entered by the user
      withdrawAmount: null,
      withdrawFee: 0,
      withdrawOutAmount: 0,
      coinType: "",
      coinList: [],
      tableWithdraw: [],
      allTableWithdraw: [],
      // isBscModalOpen: false,
      isPhoneModalOpen: false,
      isUserFundsVerified: false,
      maxWithdrawData: {},
      selectedCoinNetworks: [],
      loadingTransferBtn: false,
      otpType: "",
    };
  },
  watch: {
    currentCoin: function () {
      if (this.transferType === 1) {
        this.withdrawFee =
          this.currentCoin.minTxFee +
          (this.currentCoin.maxTxFee - this.currentCoin.minTxFee) / 2;
      } else if (this.transferType === 18) {
        this.withdrawFee = this.currentCoin.transferFee;
      }
    },
    // transferType(newValue, oldValue) {
    //     if (newValue !== oldValue) {
    //         // this.getAddrList();\
    //         this.transferType = newValue;
    //         this.sendcodeValue = this.$t("uc.regist.sendcode");
    //         this.codeIsSending = false;
    //         clearInterval(this.timercode);
    //         // if (newValue === 1) {
    //         //     this.isBscModalOpen = true;
    //         // }
    //     }
    // }
  },
  computed: {
    ...mapGetters({
      getWithdrawStatus: "global/GET_WITHDRAW_STATUS",
      getMember: "global/MEMBER",
    }),
    filteredCoinList() {
      let list
      if (this.transferType === 18) {
        list = this.coinList.filter(
            (coin) => coin.canTransfer === 1
        );
      } else {
        list = this.coinList.filter(
            (coin) => coin.canWithdraw === 1
        );
      }
      return list;
    },
    member() {
      return this.getMember;
    },
    pureAmount() {
      return parseFloat(removeThousand(this.formInline.amount));
    },
    isAmountEnable() {
      return this.formInline.coin && this.formInline.network;
    },
    selectedNetwork() {
      if (!this.formInline.coin) return;
      let data =
        this.selectedCoinNetworks &&
        this.selectedCoinNetworks.find(elem => elem.networkIso === this.formInline.network);
      this.networkRegex = data && data.addressRegex;
      return data;
    },
    maxAmount() {
      return this.selectedNetwork
        ? this.selectedNetwork.maxWithdrawAmount
        : "0";
    },
    minAmount() {
      return this.selectedNetwork
        ? this.selectedNetwork.minWithdrawAmount
        : "0";
    },
    scale() {
      // if (this.selectedNetwork) withdrawScale = this.selectedNetwork.withdrawScale;
      // else if (this.coinList && this.formInline.coin) withdrawScale = this.coinList.find(elem => elem.unit === this.formInline.coin).withdrawScale;
      let withdrawScale = "0"
      if (this.coinList && this.coinList.length && this.formInline.coin) withdrawScale = this.coinList.find(elem => elem.unit === this.formInline.coin).withdrawScale;
      return withdrawScale;
    },
    amount() {
      return this.formInline.amount;
    },
    networkFee() {
      return this.selectedNetwork ? this.selectedNetwork.maxTxFee : "0";
    },
    netAmount() {
      if (this.amount) {
        const networkFee =
          this.networkFee !== 0
            ? removeThousand(this.networkFee)
            : this.networkFee;
        const amount = removeThousand(this.amount);
        const networkFeeExponentLength = networkFee.toString().split(".")[1]
          ? networkFee.toString().split(".")[1].length
          : 0;
        const amountExponentLength = amount.split(".")[1]
          ? amount.split(".")[1].length
          : 0;
        const newAmount =
          parseFloat(amount) *
          Math.pow(10, parseFloat(networkFeeExponentLength));
        const newNetworkFee =
          parseFloat(networkFee) *
          Math.pow(10, parseFloat(networkFeeExponentLength));
        const lastCalc =
          (newAmount - newNetworkFee) *
          (1 / Math.pow(10, parseFloat(networkFeeExponentLength)));
        const result =
          amountExponentLength > networkFeeExponentLength
            ? lastCalc.toFixed(amountExponentLength)
            : lastCalc.toFixed(networkFeeExponentLength);
        return result === "NaN" || result < 0 ? 0 : result;
      }
    },
    balance() {
      const coin = this.coinList.find(
        (coin) => coin.unit === this.formInline.coin
      );
      return coin ? parseFloat(coin.strBalance).toFixed(this.scale) : 0;
    },
    canWithdraw() {
      return this.userAccountSettings.fundsVerified === 1;
    },
    sumDailyWithdrawPercent() {
      return (
        (this.maxWithdrawData.sumDailyWithdraw /
          this.maxWithdrawData.maxDailyWithdraw) *
        100
      );
    },
    withdrawStatus() {
      return this.getWithdrawStatus;
    },
    // selectedCoinNetworks() {
    //     return this.networkList[this.formInline.coin];
    // },
    networkHasMemo() {
      // const networks = this.networkList[this.formInline.coin];
      const network =
        this.selectedCoinNetworks &&
        this.selectedCoinNetworks.find(
          (network) => network.network === this.formInline.network
        );
      return network && network.accountType === 1;
    },
  },
  created() {
    this.getType();
    this.getUserWallet();
    this.setNavigation("nav-transfer");
    this.getMemberData();
    // this.coinType = this.$route.query.name || "";
    // this.getAddrList();
    this.getList(0, 10, 1);
    this.getMemberMaxWithdrawAmount();
    if (this.$route.query.name) {
      const unit = this.$route.query.name;
      this.formInline = {
        ...this.formInline,
        coin: unit,
      };
      // this.getNetworkList(unit);
    }
  },
  methods: {
    ...mapActions({
      setNavigation: "global/NAVIGATE",
      getMemberMaxWithdraw: "userCenter/GET_MEMBER_MAX_WITHDRAW_SERVICE",
      getTransactionTypes: "userCenter/GET_TRANSACTION_TYPES_SERVICE",
      getWithdrawVerificationCode:
        "userCenter/GET_WITHDRAW_VERIFICATION_CODE_SERVICE",
      applyVerificationCode: "userCenter/APPLY_VERIFICATION_CODE_SERVICE",
      applyWithdraw: "userCenter/APPLY_WITHDRAW",
      getWithdrawAddressesList:
        "userCenter/GET_WITHDRAW_ADDRESSES_LIST_SERVICE",
      getWithdrawRecords: "userCenter/GET_WITHDRAW_RECORDS_SERVICE",
      getUserData: "userCenter/GET_MEMBER_DATA_SERVICE",
      getCoinNetwork: "userCenter/GET_COIN_NETWORK",
      getAllAddresses: "userCenter/GET_ALL_ADDRESSES",
      getWallet: "userCenter/WALLET_SERVICE",
      getTfaType: "userCenter/GET_TFA_TYPE"
    }),
    changeSendingCode() {
      // this.transferType = val;
      this.amountError = "";
      // this.formInline.network = "";
      this.formInline.amount = "";
      // this.formInline.address = "";
      this.sendCodeValue = this.$t("withdraw.getCode");
      this.codeIsSending = false;
      clearInterval(this.timercode);
    },
    setTotalBalance() {
      this.formInline.amount = handleInput(this.balance.toString(), this.scale);
      // this.checkAmountRange();
    },
    getMemberMaxWithdrawAmount() {
      this.getMemberMaxWithdraw().then((response) => {
        if (response.data.code === 200) {
          this.maxWithdrawData = response.data.data;
        }
      });
    },
    onValidateAddress(address) {
      this.withdrawAdress = address;
      const regex = /^(0[xX])?[0-9a-fA-F]{40}$/;
      if (regex.test(address)) {
        this.isAddressValid = true;
      } else {
        this.isAddressValid = false;
      }
    },
    // confirmBscModal() {
    //     this.isBscModalOpen = false;
    // },
    getType() {
      this.getTransactionTypes()
        .then((response) => {
          this.recordType = response.data.data;
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
    cancel() {
      this.modal = false;
      this.formInline.code = "";
      this.formInline.fundpwd = "";
      clearInterval(this.timercode);
      this.sendCodeValue = this.$t("withdraw.getCode");
      this.codeIsSending = false;
      this.$refs["securityForm"].resetFields();
    },
    sendCode() {
      this.getWithdrawVerificationCode()
        .then((response) => {
          if (response.data.code === 0) {
            this.setTime();
            this.$toast.success(response.data.message);
          } else if (response.data.code === 302) {
            this.isPhoneModalOpen = true;
          } else {
            this.$toast.error(response.data.message);
          }
          this.formInline.code = "";
          this.formInline.fundpwd = "";
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
    confirmUserHasNoMobile() {
      window.location.replace(this.baseUrl + "/panel/account/kycresult");
    },
    setTime() {
      this.sendCodeValue = this.countdown;
      this.codeIsSending = true;
      this.timercode = setInterval(() => {
        this.countdown--;
        this.sendCodeValue = this.countdown;
        if (this.countdown <= 0) {
          clearInterval(this.timercode);
          this.sendCodeValue = this.$t("withdraw.getCode");
          this.countdown = 120;
          this.codeIsSending = false;
        }
      }, 1000);
    },
    changePage(index) {
      this.tablePaginate.page = index - 1;
      this.getList();
    },
    onAddressChange(data) {
      this.inputAddress = data;
    },
    clearValues() {
      this.withdrawAdress = "";
      this.inputAddress = "";
      this.withdrawAmount = null;
      // this.withdrawFee= 0;
      this.withdrawOutAmount = 0;
    },
    getCurrentCoinRecharge() {
      if (this.coinType != "") {
        var temp = [];
        for (var i = 0; i < this.allTableWithdraw.length; i++) {
          //   if (this.allTableWithdraw[i].symbol == this.coinType) {
          if (this.allTableWithdraw[i].coin.unit == this.coinType) {
            temp.push(this.allTableWithdraw[i]);
          }
        }
        this.tableWithdraw = temp;
      } else {
        this.tableWithdraw = this.allTableWithdraw;
      }
    },
    getOtpType() {
      this.loadingTransferBtn = true;
      this.getTfaType("WITHDRAW")
          .then(response => {
              this.otpType = response.data.data;
              this.apply();
          })
          .catch(err => {
            this.$toast.error(err.message);
          })
          .finally(() => {
        this.loadingTransferBtn = false;
      })
    },
    withdrawRequest() {
      this.$refs["securityForm"].validate((valid) => {
        if (valid) {
          // let params = {};
          // for (let i = 0; i < this.currentCoin.addresses; i++) {
          //     if (this.currentCoin.addresses[i].address == this.withdrawAdress) {
          //         params["remark"] = this.currentCoin.addresses[i].remark;
          //     }
          // }
          this.withdrawLoading = true;
          let payload = {
            unit: this.formInline.coin,
            // network: this.formInline.network,
            // address: this.formInline.address.trim(),
            // tag: this.formInline.memo,
            amount: removeThousand(this.formInline.amount),
            // fee: this.networkFee,
            // jyPassword: this.securityForm.fundpwd,
            code: this.securityForm.code,
            transactionType: this.transferType,
          };
          this.applyWithdraw(payload)
            .then((response) => {
              this.$toast.success(response.data.message);
              this.modal = false;
              this.tablePaginate.page = 0;
              this.getUserWallet();
              this.getMemberData();
              this.getList(0, 10, 1);
              this.getMemberMaxWithdrawAmount();
              this.cancel();
              this.$refs["formInline"].resetFields();
              this.$refs["securityForm"].resetFields();
              if (this.$route.query.name) {
                const unit = this.$route.query.name;
                this.formInline = {
                  ...this.formInline,
                  coin: unit,
                };
                // this.getNetworkList(unit);
              }
            })
            .catch((err) => {
              this.$toast.error(err.message);
            })
            .finally(() => {
              this.withdrawLoading = false;
            });
        }
      });
      // if (this.securityForm.code === '') {
      //     this.modal = true;
      //     this.$Message.error("لطفا کد پیامک شده را وارد نمایید.");
      //     return;
      // }
      // if (this.securityForm.fundpwd === '') {
      //     this.modal = true;
      //     this.$Message.error(this.$t("otc.chat.msg7tip"));
      //     return;
      // }
    },
    onChangeCoin(coin) {
      if (coin) {
        this.$router.replace("/transfer?name=" + coin, () => {});
      }
      // this.getNetworkList();
      this.amountError = "";
      // this.formInline.network = "";
      this.formInline.amount = "";
      // this.formInline.address = "";
    },
    getAddrList() {
      //initialize the value on the page
      this.clearValues();
      //get address
      let payload = {
        transactionType: this.transferType,
      };
      this.getWithdrawAddressesList(payload)
        .then((response) => {
          let coinsData = response.data.data;
          if (coinsData.length > 0) {
            this.coinList = coinsData;
            if (this.coinType) {
              for (let i = 0; i < coinsData.length; i++) {
                if (this.coinType === coinsData[i].unit) {
                  this.currentCoin = coinsData[i];
                  break;
                }
              }
            } else {
              this.currentCoin = this.coinList[0];
              this.coinType = this.currentCoin.unit;
            }
          } else if (this.isUserFundsVerified) {
            this.currentCoin.balance = 0;
            this.coinList = [];
            this.coinType = "";
            this.$toast.error(this.$t("withdraw.notAbleToTransfer"));
          }
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
    getList() {
      this.tableLoading = true;
      //get table withdraw
      let payload = {
        page: this.tablePaginate.page,
        pageSize: this.tablePaginate.pageSize,
      };
      this.getWithdrawRecords(payload)
        .then((response) => {
          let withdrawData = response.data.data;
          if (response.data.code === 0) {
            this.tableWithdraw = withdrawData.content;
            this.tablePaginate.total = withdrawData.totalElements;
            this.tablePaginate.page = withdrawData.number;
          }
        })
          .catch(err => {
            this.$toast.error(err.message);
          })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // accSub(arg1, arg2) {
    //     var r1, r2, m, n;
    //     try {
    //         r1 = arg1.toString().split(".")[1].length;
    //     } catch (e) {
    //         r1 = 0;
    //     }
    //     try {
    //         r2 = arg2.toString().split(".")[1].length;
    //     } catch (e) {
    //         r2 = 0;
    //     }
    //     m = Math.pow(10, Math.max(r1, r2));
    //     //last modify by deeka
    //     //dynamic control precision length
    //     n = r1 >= r2 ? r1 : r2;
    //     return ((arg1 * m - arg2 * m) / m).toFixed(n);
    // },
    setAmount() {
      this.formInline.amount = handleInput(this.formInline.amount, this.scale);
      // this.checkAmountRange();
    },
    // checkAmountRange() {
    //     const withdrawAmount = this.removeThousand(this.withdrawAmount);
    //     if (this.currentCoin.maxAmount < parseFloat(withdrawAmount)) {
    //         this.withdrawAmountRule = `حداکثر مقدار قابل برداشت ${this.currentCoin.maxAmount} ${this.currentCoin.unit} است`;
    //     } else if (parseFloat(withdrawAmount) < this.currentCoin.minAmount) {
    //         this.withdrawAmountRule = `حداقل مقدار قابل برداشت ${this.currentCoin.minAmount} ${this.currentCoin.unit} است`;
    //     } else {
    //         this.withdrawAmountRule = "";
    //     }
    //     this.withdrawOutAmount = this.accSub(withdrawAmount, this.withdrawFee);
    //     if (this.withdrawOutAmount < 0 || this.withdrawOutAmount === "") {
    //         this.withdrawOutAmount = 0;
    //     }
    // },
    valid() {
      const withdrawAmount = this.withdrawAmount;
      this.withdrawAdress = this.withdrawAdress || this.inputAddress;
      if (this.coinType == "") {
        this.$Message.error(this.$t("uc.finance.withdraw.symboltip"));
        return false;
      } else if (
        (this.withdrawAdress == "" || !this.isAddressValid) &&
        this.transferType !== 18
      ) {
        this.$Message.error(this.$t("uc.finance.withdraw.addresstip"));
        return false;
      } else if (
        withdrawAmount == "" ||
        withdrawAmount == 0 ||
        withdrawAmount < this.currentCoin.minAmount
      ) {
        this.$Message.error(
          this.$t("uc.finance.withdraw.numtip2") + this.currentCoin.minAmount
        );
        return false;
      } else if (withdrawAmount - 0 < this.withdrawFee) {
        this.$Message.error(this.$t("uc.finance.withdraw.numtip3"));
        return false;
      } else {
        return true;
      }
      // else if (
      //   (this.withdrawFee == "" ||
      //   this.withdrawFee == 0 ||
      //   this.withdrawFee - 0 > this.currentCoin.maxTxFee ||
      //   this.withdrawFee - 0 < this.currentCoin.minTxFee)
      // ) {
      //   this.$Message.error(
      //     this.$t("uc.finance.withdraw.feetip1") +
      //       this.currentCoin.minTxFee +
      //       " , " +
      //       this.$t("uc.finance.withdraw.feetip2") +
      //       this.currentCoin.maxTxFee
      //   );
      //   return false;
      // }
    },
    apply() {
      // const withdrawAmount = this.withdrawAmount;
      // if (this.codeIsSending) {
      //     this.settime()
      // }
      // if (this.currentCoin.maxAmount < parseFloat(withdrawAmount)) {
      //     this.$Message.error("مقدار وارد شده برای برداشت از حداکثر مقدار قابل برداشت بیشتر است.");
      //     return;
      // } else if (parseFloat(withdrawAmount) < this.currentCoin.minAmount) {
      //     this.$Message.error("مقدار وارد شده برای برداشت از حداقل مقدار قابل برداشت کمتر است.");
      //     return;
      // } else if (this.currentCoin.balance === 0 || parseFloat(withdrawAmount) > this.currentCoin.balance) {
      //     this.$Message.error("موجودی قابل برداشت کافی نمی‌باشد.");
      //     return;
      // }
      this.$refs["formInline"].validate((valid) => {
        if (valid) {
          if (this.codeIsSending) {
            this.setTime();
          }
          // this.withdrawLoading = true;
          this.modal = true;
          let timercode = setInterval(() => {
            if (this.countdown <= 0) {
              clearInterval(timercode);
              this.sendCodeValue = this.$t("withdraw.getCode");
              this.codeIsSending = false;
            }
          }, 1000);
        }
      });
    },
    getUserWallet() {
      this.getWallet().then((res) => {
        this.coinList = res.data.data;
      });
    },
    getMemberData() {
      //obtain personal safety information
      this.getUserData()
        .then((response) => {
          this.userAccountSettings = response.data.data;
          this.isUserFundsVerified =
            this.userAccountSettings.fundsVerified === 1;
          // if (this.userAccountSettings.fundsVerified === 0) {
          //   this.$toast.error(this.$t("withdraw.setWithdrawPass"));
          //   this.$router.push("/profile");
          // }
        })
        .catch((err) => {
          this.$toast.error(err.message);
        });
    },
    getNetworkList() {
      if (this.formInline.coin === "") return;
      this.getCoinNetwork(this.formInline.coin).then((res) => {
        const canWithdrawsNetwork = res.data.data.filter(
          (network) => network.canWithdraw === 1
        );
        if (canWithdrawsNetwork.length > 0) {
          if (this.formInline.coin) {
            this.networkList = {
              ...this.networkList,
              [this.formInline.coin]: canWithdrawsNetwork,
            };
            this.selectedCoinNetworks = this.networkList[this.formInline.coin];
          }
        }
      });
    },
    onChangeNetwork(network) {
      this.formInline.amount = "";
      const payload = {
        network,
        unit: this.formInline.coin,
      };
      this.getAllAddresses(payload).then((response) => {
        this.addressDropDown = response.data.data;
      });
    },
    setDestinationTag(value) {
      this.addressDropDown.find((elem) => {
        if (elem.address === value) {
          this.formInline.memo = elem.destinationTag;
        } else {
          this.formInline.memo = "";
        }
      });
    },
  },
};
