import { render, staticRenderFns } from "./Withdraw.html?vue&type=template&id=00588b62&scoped=true&"
import script from "./Withdraw.js?vue&type=script&lang=js&"
export * from "./Withdraw.js?vue&type=script&lang=js&"
import style0 from "./withdraw.scss?vue&type=style&index=0&id=00588b62&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00588b62",
  null
  
)

export default component.exports