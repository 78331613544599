import Loader from "@/components/UI/LoadingComp"

export default {
  name: "withdraw-table",
  components: {
    Loader
  },
  props: {
    dataTable: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
    paginate: {
      type: Object,
    },
  },
  data() {
    return {
      tableColumnsWithdraw: [
        {
          title: this.$t("withdraw.withdrawTime"),
          sortable: true,
          minWidth: 200,
          align: "center",
          key: "createTime",
          render: (h, params) => {
            return h(
              "div",
              {
                style: {
                  direction: "ltr",
                },
              },
              this.$options.filters.dateFormat(params.row.createTime)
            );
          },
        },
        {
          title: this.$t("withdraw.crypto"),
          key: "symbol",
          minWidth: 80,
          align: "center",
          render: function (h, params) {
            return h("span", params.row.coin.unit);
          },
        },
        // {
        //   title: this.$t("withdraw.address"),
        //   minWidth: 100,
        //   key: "address",
        //   align: "center",
        // },
        // {
        //   title: this.$t("withdraw.amount"),
        //   minWidth: 100,
        //   key: "totalAmount",
        //   align: "center",
        //   render: (h, params) => {
        //     return h("span", {}, this.numberWithCommas(params.row.totalAmount));
        //   },
        // },
        // {
        //   title: this.$t("withdraw.fee"),
        //   minWidth: 120,
        //   key: "fee",
        //   align: "center",
        //   render: (h, params) => {
        //     return h("span", {}, this.numberWithCommas(params.row.fee));
        //   },
        // },
        // {
        //   title: this.$t("withdraw.netAmount"),
        //   minWidth: 140,
        //   key: "arrivedAmount",
        //   align: "center",
        //   render: (h, params) => {
        //     return h(
        //       "span",
        //       {},
        //       this.numberWithCommas(params.row.arrivedAmount)
        //     );
        //   },
        // },
        // {
        //   title: this.$t("withdraw.trxHash"),
        //   key: "transactionNumber",
        //   minWidth: 120,
        //   align: "center",
        //   render: (h, params) => {
        //     if (
        //       params.row.transactionType === "TRANSFER_FROM_MARKET_TO_EXCOINO"
        //     ) {
        //       return;
        //     } else {
        //       return h("div", [h("p", params.row.transactionNumber)]);
        //     }
        //   },
        // },
        {
          title: this.$t("withdraw.status"),
          minWidth: 200,
          key: "status",
          align: "center",
          render: (h, params) => {
            return h(
              "div",
              [h("p", params.row.statusString)]
            );
          },
        },
        {
          title: this.$t("withdraw.refCode"),
          key: "track_number",
          minWidth: 120,
          align: "center",
          render: (h, params) => {
            return h("div", params.row.trackNumber);
          },
        },
        {
          title: this.$t("withdraw.withdrawType"),
          key: "status",
          align: "center",
          minWidth: 200,
          render: (h, params) => {
            return h("div", [
              h(
                "p",
                params.row.transactionType === "TRANSFER_FROM_MARKET_TO_EXCOINO"
                  ? this.$t("withdraw.transferToExcoino")
                  : params.row.transactionType === "WITHDRAW"
                  ? this.$t("withdraw.withdraw")
                  : ""
              ),
            ]);
          },
        },
      ],
    };
  },
  computed: {},
  mounted() {},
  methods: {
    changePageNumber(pageIndex) {
      this.$emit("changePage", pageIndex);
    },
  },
};
